import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';

import backgroundImage from 'assets/img/contact-bg.jpg';
import successPageStyle from 'assets/jss/material-kit-react/views/successPage';

const SuccessPage = ({ classes, data }) => {
  if (!data) return null;

  const content = data.prismicSuccessPage.data;
  const { lang } = data.prismicSuccessPage;
  const alternateLanguages = data.prismicSuccessPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title="Servitours - Merci" />
      <Parallax filter image={backgroundImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>{RichText.asText(content.title.raw)}</h1>
                <h3 className={classes.subtitle}>{RichText.asText(content.subtitle.raw)}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </Layout>
  );
};
// Query for the Success page content in Prismic
export const query = graphql`
  query SuccessQuery($lang: String = "fr-fr") {
    prismicSuccessPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        subtitle {
          raw
        }
        title {
          raw
        }
      }
      lang
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(successPageStyle)(SuccessPage);
